import React, { useState } from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import CommunityTemplate from "../../component/CommunityTemplate";

const CommunityDetail = (props: any) => {
  const [siteTitle, setSiteTitle] = useState("");
  const [siteDesc, setSiteDesc] = useState("");
  const pageData = {
    id: "university-heights-homes-for-sale",
    titleTag: "University Heights Homes for Sale | San Diego | Selby Team",
    descTag:
      "Find homes for sale in University Heights, centrally located in the San Diego area. Contact The Selby Team to learn more about this great community!",
  };
  return (
    <LayoutWrapper
      title={pageData.titleTag}
      desc={pageData.descTag}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <CommunityTemplate
        id={pageData.id}
        setSiteTitle={setSiteTitle}
        setSiteDesc={setSiteDesc}
        listingId="40665"
      />
    </LayoutWrapper>
  );
};

export default CommunityDetail;
